import styled, { css } from "styled-components";

import background from "./background.png";

export const EditContainer = styled.section`
  display: flex;
  width: 60%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
  }
`;
export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  height: 144px;
  background: #251561;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  justify-content: flex-end;
`;

export const ButtonText = styled.p`
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
`;

export const HeaderInnerView = styled.div`
  margin: 30px;
`;
export const Text = styled.p`
  font-size: ${({ size }) => size || 16}px;
  color: ${({ color }) => color || "#000000"};
  margin: 0;
  text-align: ${({ textAlign }) => textAlign || "left"};
  font-weight: ${({ font }) => font || null};
  ${({ $opacity }) =>
    $opacity &&
    css`
      opacity: 0.8;
    `}

  ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

    ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight}px;
    `}
`;
export const AddActionContainer = styled.div`
  min-height: 0.9vh;
  flex-grow: 1;
  padding: 24px 24px;
  z-index: 0;
  background: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const TouchableBox = styled.button`
  width: 120px;
`;

export const AddBiteText = styled.p`
  font-size: 16px;
  color: #97a6bf;
  margin-bottom: 2.5px;
`;

export const BiteDetailsText = styled.p`
  font-size: 28px;
  color: #ffffff;
  margin-top: 2.5px;
`;

export const CategoryScrollView = styled.section``;

export const ModalCloseView = styled.button`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border-color: #251561;
  border-width: 1px;
  margin-left: 20px;
  margin-bottom: 5px;
`;

export const ModalCloseText = styled.p`
  font-size: 12px;
  color: #251561;
`;

export const LocationModalView = styled.div`
  flex: 1;
  justify-content: flex-start;
  background: #ffffff;
`;

export const LocationListItemView = styled.button`
  border-bottom-width: 1px;
  border-bottom-color: #dde4ef;
  padding: 10px;
`;

export const LocationListItemTitleText = styled.p`
  font-size: 20px;
  color: #251561;
`;

export const LocationListItemDescText = styled.p`
  font-size: 14px;
  color: #251561;
`;

export const EventImage = styled.img`
  display: flex;
  flex: 1;
  width: 70%;
`;

export const IconImage = styled.img`
  width: 5em;
  height: 5em;
  position: absolute;
  left: 12px;
  bottom: 12px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderSpaceView = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderCross = styled.p`
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 2.5px;
  margin-right: 24px;
`;

export const ModalView = styled.div`
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 15px;
`;

export const EditNavigationText = styled.p`
  font-size: 20px;
  color: #251561;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #97a6bf;
    `}
`;

export const TopView = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const ConfirmScrollView = styled.div`
  flex: 1;
  background-color: #251561;
  padding: 28px;
  justify-content: space-evenly;
`;

export const UserImagesView = styled.div`
  margin: 24px 24px 0px 0px;
  width: 100%;
`;

export const CenteredView = styled.div`
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 48px;
  padding: 4px 8px 4px 8px;
  width: 100%;
`;

export const DetailsText = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #251561;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 24px;
`;

export const EventTitle = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 28)}px;
  color: #251561;
  margin-top: ${(props) => (props.disableMargin ? 12 : 24)}px;
  margin-bottom: ${(props) => (props.disableMargin ? 8 : 0)}px;
  font-weight: 700;
`;

export const BottomView = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 0.5vh;
`;

export const DescriptionText = styled.p`
  font-size: 14px;
  color: #251561;
  margin-top: ${(props) => (props.disableMargin ? 0 : 5)}px;
  margin-bottom: ${(props) => (props.disableMargin ? 0 : 5)}px;
  margin-left: 24px;
  margin-right: 24px;
  line-height: 20px;
`;

export const ThemeText = styled.p`
  font-size: 14px;
  color: #251561;
`;

export const ReadMore = styled.p`
  font-size: 14px;
  color: #000000;
  margin-top: ${(props) => (props.disableMargin ? 0 : 5)}px;
  margin-bottom: ${(props) => (props.disableMargin ? 0 : 5)}px;
`;

export const IconView = styled.button`
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 8px;
  background: #251561;
  margin: 0px 16px;
`;

export const ProfileView = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
`;

export const MapImagesView = styled.div`
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 8px 10px;
`;

export const EventTypeView = styled.div`
  margin-left: ${(props) => (props.leftSpace ? 16 : 0)}px;
  flex-grow: 0;
  align-self: flex-start;
  padding: 8px;
  margin-top: -15px;
  border-width: 0.5px;
  background: #ffffff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

export const IconBox = styled.div`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: #dde4ef;
  border-radius: 2.5px;
`;

export const SimpleView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 48px;
`;

export const SpaceBetweenView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  border-radius: 8px;
  background-color: #dde4ef;
`;

export const FloatingBetweenView = styled(SpaceBetweenView)`
  position: absolute;
  width: 100%;
`;

export const BetweenView = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const FilterSpaceBetweenView = styled.button`
  z-index: 500;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 8px 24px 8px 24px;
`;

export const NumColumnView = styled.div`
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

export const ConfirmButton = styled.button`
  display: flex;
  background-color: #2846e8;
  justify-content: center;
  align-items: center;
  margin: 0px 24px 32px 24px;
  width: 80%;
  height: 58px;
  border-radius: 8px;
  border-width: 0;
  padding: 8px;
`;

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #dde4ef;
  justify-content: center;
  @media (min-width: 768px) {
    width: 60%;
    height: 100vh;
  }
`;

export const ConfirmView = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  width: 90%;
  /* min-height: 0.66vh; */
  padding: 0px 24px 0px 24px;
  background: #ffffff;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  justify-content: space-evenly;
  margin-top: 24px;
`;

export const BackgroundImage = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${background});
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
`;

export const ExtraView = styled.div`
  height: 0.5vh;
`;

export const SpinnerContainer = styled.div`
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  align-self: center;
  background: #ffffff;
`;

export const TextIcon = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 28)}px;
  color: #000000;
`;

export const ConfirmDetailsView = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
`;

export const ConfirmDescText = styled.p`
  font-size: 14px;
  color: #97a6bf;
`;

export const SmallDescText = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #97a6bf;
  margin-left: 24px;
  margin-top: 8px;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const BoxTouchable = styled.button`
  height: 120px;
  width: 30%;
  max-width: 150px;
  background-color: ${(props) => props.background};
  border-radius: 8px;
  border-color: #ffffff;
  border-width: 2px;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.first ? "0px" : "5px")};
  margin-right: ${(props) => (props.first ? "0px" : "5px")};
  margin-top: ${(props) => (props.marginVertical ? props.marginVertical : 5)}px;
  margin-bottom: ${(props) =>
    props.marginVertical ? props.marginVertical : 5}px;
`;

export const InnerBoxView = styled.div`
  height: 48px;
  width: 48px;
  background: #ffffff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

export const TextTitle = styled.p`
  font-size: 16px;
  color: #251561;
  margin-top: 5px;
`;

export const ModalButton = styled.button`
  background: #251561;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 58px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const CenterView = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const ProcessFinishText = styled.p`
  font-size: 24px;
  color: #ffffff;
`;

export const ProcessFinishDescText = styled.p`
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin: 2.5% 0 10% 0;
  line-height: 24px;
`;

export const ProcessFinishView = styled.div`
  align-items: center;
  margin: 20% 0%;
`;

export const SendRequestView = styled.div`
  align-items: center;
  margin: 24px;
  height: 30%;
`;

export const SendRequestButton = styled.button`
  background: #251561;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 58px;
  border-radius: 8px;
  margin-bottom: 24px;
`;
