// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6714dnEmWuXQzHbcar8thDBtmk-q0emw",
  authDomain: "bite-eb0b2.firebaseapp.com",
  databaseURL: "https://bite-eb0b2-default-rtdb.firebaseio.com",
  projectId: "bite-eb0b2",
  storageBucket: "bite-eb0b2.appspot.com",
  messagingSenderId: "80893784318",
  appId: "1:80893784318:web:87899d70fa620373cd35af",
  measurementId: "G-6JJ4YJX0G3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
