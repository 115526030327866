import "./App.css";

import React from "react";
import { format, fromUnixTime } from "date-fns/esm";
import picture from "./fullParty.png";
import logo from "./logo.png";
import {
  ConfirmDetailsView,
  DetailsBox,
  DetailsText,
  IconBox,
  SimpleView,
  SpaceBetweenView,
  TextIcon,
  ConfirmDescText,
  EditContainer,
  ConfirmButton,
  ConfirmContainer,
  ConfirmView,
  BottomView,
  ButtonText,
  EventTitle,
  EventImage,
  SmallDescText,
  Text,
  MainContainer,
  BackgroundImage,
  IconImage,
  UserImagesView,
} from "./styles";
import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

import { getQueryParamFromUrlByKey } from "./getQueryByKey";

const url = window.location.href;
export const App = () => {
  const [singleEvent, setEvent] = React.useState();
  const id = getQueryParamFromUrlByKey(url, "id");
  const getHangout = async () => {
    const hangout = doc(db, "Events", id);
    const oneHangout = await getDoc(hangout);

    setEvent(oneHangout.data());
  };

  React.useEffect(() => {
    getHangout();
  }, []);

  const { location, description, title, startedAt, finishedAt, participants } =
    singleEvent || {};

  // const findIcon = categories.find((r) => r.title === theme)?.icon;
  // const exist = Boolean(
  //   participants?.find((r: i.Participants) => r.id === getUserId())
  // );
  // const isMyEvent = getUserId() === hostId;
  // const isParticipant = participants.find((item) => item.id === user.userID);
  const hostName = participants?.length > 0 ? participants[0]?.name : "";
  // const isAccepted = isParticipant?.status === "approved";
  // const isPending = isParticipant?.status === "pending";
  // const isOver = Date.now() / 1000 > finishedAt;

  const startDate = fromUnixTime(startedAt || Date.now());
  const endDate = fromUnixTime(finishedAt || Date.now());
  const shortenedLocation = location?.split(",");

  const details = [
    {
      icon: "📅",
      title: format(startDate, "EEEE, d LLLL"),
      desc: `${format(startDate, "HH:mm")} - ${format(endDate, "HH:mm")}`,
    },
    {
      icon: "🏖",
      title:
        shortenedLocation?.length >= 3
          ? `${shortenedLocation[0]}, ${shortenedLocation[1]}, ${shortenedLocation[2]}`
          : location
          ? location
          : "Neverland",
      desc: "More info",
    },
    {
      icon: "👤",
      title: "Initiated by",
      desc: hostName || "Alex",
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    window.location.href = "http://onelink.to/ctvkyz";
  };

  return (
    <BackgroundImage>
      <IconImage src={logo} />
      <MainContainer>
        <EditContainer>
          <ConfirmContainer>
            <EventImage src={picture} />
            <ConfirmView>
              <Text
                font={700}
                size={28}
                color={"#251561"}
                margin={"16px 0px 0px 0px"}
              >
                {title || "Fun hangout!"}
              </Text>
              <ConfirmDetailsView>
                {details.map((r, i) => (
                  <SpaceBetweenView key={r + i.toString()} marginTop={25}>
                    <DetailsBox>
                      <IconBox>
                        <TextIcon fontSize={"12"}>{r.icon}</TextIcon>
                      </IconBox>
                      <SimpleView>
                        <DetailsText disableMargin>{r.title}</DetailsText>
                        <SmallDescText>{r.desc}</SmallDescText>
                      </SimpleView>
                    </DetailsBox>
                  </SpaceBetweenView>
                ))}
              </ConfirmDetailsView>
              <UserImagesView>
                <Text font="bold" size={16} color={"#251561"}>
                  {participants?.length || 1}{" "}
                  {participants?.length === 1 ? "person is" : "people are"}{" "}
                  joining
                </Text>
              </UserImagesView>
              {true ? (
                <div>
                  <EventTitle fontSize={"20"} disableMargin>
                    {"About the Bite"}
                  </EventTitle>
                  <ConfirmDescText>
                    {description ||
                      "A fun hangout that is about to happen, so be there or be square!"}
                  </ConfirmDescText>
                </div>
              ) : null}
              <BottomView>
                <ConfirmButton onClick={onSubmit}>
                  <ButtonText>{"Show your interest!"}</ButtonText>
                </ConfirmButton>
              </BottomView>
            </ConfirmView>
          </ConfirmContainer>
        </EditContainer>
      </MainContainer>
    </BackgroundImage>
  );
};

export default App;
